import React from "react";

import Connections from "../../scripts/connection"


class CodeForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            timeLeft: 1,
        }
    }

    componentDidMount() {

        this.setUpTimer();
    }

    setUpTimer = () => {
        let time = 60;
        let nextCodeIn = setInterval(() => {
            let timeLeft = this.state.time ?? time;
            timeLeft -= 1;
            if (timeLeft <= 0)
            {
                clearInterval(this.state.nextCodeIn);
                timeLeft = undefined
            }
            this.setState({nextCodeIn: nextCodeIn, time: timeLeft});
        }, 1000);
    }

    receiveCode = () => {
        Connections.Post('/auth/generateotc', 
            {phoneNumber: this.props.phone},
            (result) => {
                this.setUpTimer();
            },
            (result) => {
                this.setState({message: "Произошла ошибка, повторите попытку позже"})
            },
            (err) => {
                this.setState({message: "Сервис временно недоступен, попробуйте позже"})
            });
        
    }

    login = () => {
        Connections.Post('/auth/auth',{
            phoneNumber: this.props.phone,
            code: this.state.code
        },
        (result) => {
            console.log(result);
            localStorage.setItem('token', result.value.token);
            this.props.onAuth();
        },
        (result) => {

            if (result.value.code === 'user_not_found')
                this.setState({message: 'Пользователь не найден'});
            else if (result.value.code === 'incorrect_code')
                this.setState({message: 'Неверный код'})
            else if (result.value.code === 'code_already_applied')
                this.setState({message: 'Код уже был использован'})
            else if (result.value.code === 'code_expired')
                this.setState({message: 'Срок действия кода истек'})
            else
                this.setState({message: 'Произошла ошибка, повторите попытку позже'});
        },
        (err) => {
            this.setState({message: "Сервис временно недоступен, попробуйте позже"});
        });
    }

    timeToView = (time) => {
        let minutes = parseInt(time / 60);
        let seconds = time % 60;
        let secondsText = seconds < 10 ? '0' + seconds : seconds;
        return minutes + ':' + secondsText;
    }


    render() {
    
        return (<div className='login-content'>
                    <div className='login-form'>
                        <h2 className='login-title'>Введите код</h2>
                        <div className='login-info code-info'>
                            <div>Мы отправили SMS с кодом на телефон <span style={{color: 'black'}}>{this.props.phone}</span></div>
                        </div>
                        <div className='login-field'>
                            <input className='field-text' style={{textAlign: 'center', border: 'none', width: '100%'}} type="text" value={this.state.code} onChange={(event) => { this.setState({code: event.target.value})}}/>
                        </div>
                        <div className='login-button-container'>
                            <button className='login-button' onClick={() => this.login()}>
                                Войти
                            </button>
                        </div>
                        <div>
                            {this.state.time ?
                            <div className='code-message-container'><div className='code-message'>Отправить SMS через {this.timeToView(this.state.time)}</div></div>    
                            :
                            <div className='code-message-container'><div className='code-message'><span style={{cursor: 'pointer'}} onClick={() => this.receiveCode()}>Отправить повторно</span></div></div>
                            }
                        </div>
                        {this.state.message ?
                            <div className='login-info'>
                                {this.state.message}
                            </div>
                        :
                            undefined
                        }
                    </div>
                </div>);
    }
}

export default CodeForm;