import React from "react";
import AboutBlock from "./showcase/AboutBlock";
import AdvantagesBlock from "./showcase/AdvantagesBlock";
import DiscountBannerMobile from "./showcase/DiscountBannerMobile";
import EducationBlock from "./showcase/EducationBlock";
import FaqBlock from "./showcase/FaqBlock";
import OnboardingHeaderMobile from "./showcase/OnboardingHeaderMobile";
import ShowcaseMenu from "./showcase/ShowcaseMenu";
import ShowcaseMenuButton from "./showcase/ShowcaseMenuButton";
import SliderMobile from "./showcase/SliderMobile";
import SpecialistBlock from "./showcase/SpecialistBlock";
import TherapyMethodsBlock from "./showcase/TherapyMethodsBlock";
import connection from "../../scripts/connection";
import Utils from "../../scripts/utils";
import ConsultationBookingModal from "../ConsultationBookingModal";
import RegisterModal from "../entry/RegisterModal";
import FreeBookingModal from "./FreeBookingModal";
import Loader from "../Loader";
import { Navigate } from "react-router";
import { decodeToken } from "react-jwt";
import SpecialistVideoModal from "./showcase/SpecialistVideoModal";
import ShowcaseTextModal from "./showcase/ShowcaseTextModal";
import ShowcaseTimeSelectModal from "./showcase/ShowcaseTimeSelectModal";
import MessageModal from "../MessageModal";
import TelegramOnboardingHeaderMobile from "../TelegramOnboardingHeaderMobile";



class TelegramSpecialistsShowcase extends React.Component {

    constructor (props) {
        super(props);
        
        this.state = {

            showMenu: false
        }
    }


    componentDidMount () {
        let interval = setInterval(() => {
            let count = this.state.timeCount ?? -1;
            count++;

            if (count == 1 && !this.state.analyzer)
                this.setState({analyzer: true});

            if (count == 3 && !this.state.finder)
                this.setState({finder: true});

            if (count > 5)
            {
                clearInterval(interval);
            }

            this.setState({timeCount: count});

        }, 1000);

        connection.Get('/therapymethods',
        (success) => {

            let therapyMethods = {};
            success.value.forEach(item => therapyMethods[item.id] = item);

            this.setState({
                therapyMethods: therapyMethods
            })
        },
        (error) => {
            this.setState({
                therapyMethods: {}
            })
            console.log(error);
        },
        (fatalError) => {
            console.log(fatalError);
        });

        let surveyData = localStorage.getItem('survey');
        if (!surveyData && !this.props.auth)
        {
            this.setState({toSurvey: true});
            return;
        }

        let survey = JSON.parse(surveyData);
        let payload = {
            subcategoriesId: survey?.subcategoriesId?.map(i => i.subCategoryId) ?? [],
            preferredGender: survey?.preferredGender,
            timezoneOffsetInMinutes: survey?.timezoneOffset ?? new Date().getTimezoneOffset(),
            timePeriods: survey?.timePeriods ?? []
        }

        this.setState({
            subcategoriesId: survey?.subcategoriesId?.map(i => i.subCategoryId) ?? [],
            preferredGender: survey?.preferredGender,
            timezoneOffset: survey?.timezoneOffset ?? new Date().getTimezoneOffset(),
            timePeriods: survey?.timePeriods ?? []
        })

        connection.Post('/booking/availablespecialist',
        payload
        ,
        (success) => {
            this.setState({
                //selectedTime: success.value[0].availableTime[0],
                specialists: success.value,
                selectedSpecialist: success.value[0],
                loaded: true,
                //price: success.value.payment,
            })

            connection.SendStats({specialistSwitchEventData: {
                browserId: Utils.GetBrowserId(),
                specialistId: success.value[0]?.specialistId
            }})
        },
        (error) => {
            console.log(error);
            if (error.value.noSpecialists)
            {
                this.setState({loaded: true, noSpecialists: true});
            }
        },
        (requestError) => {
            console.log(requestError);
        })

        connection.SendStats({surveyViewedEventData: {
            browserId: Utils.GetBrowserId(),
            utm: window.location.search
        }})

        window.ym(94253611,'hit','platform/select');
    }

    bookConsultation = (time) => {
        let tokenData = decodeToken(localStorage.getItem('token'));

        console.log(JSON.stringify({
            clientId: tokenData.id,
            specialistId: this.state.selectedSpecialist.specialistId,
            time: time,
        }))

        connection.AuthorizePost('/booking/bookconsultation',
        {
            clientId: tokenData.id,
            specialistId: this.state.selectedSpecialist.specialistId,
            time: time,
        },
        (success) => {
            this.setState({tgClose: true})

            //console.log('zaebis opl4en0');
            // close window
        },
        (error) => {


            // consultation_create_time_gap_expired
            // there_is_no_schedule_unit

            this.setState({booking: false, message: 'Выбранное время более недоступно. Пожалуйста, обновите страницу и выберите другое.'});
        },
        (fatalError) => {

            this.setState({booking: false, message: 'Не удалось забронировать время. Сервис временно недоступен'});
        })
    }

    getSpecialistName = (fullName) => {
        
        return fullName.split(' ')[0];
    }

    specialistSelected = (index) => {
        let specialistCurr = this.state.selectedSpecialist.specialistId;
        this.setState({selectedSpecialist: this.state.specialists[index]})

        if (specialistCurr != this.state.specialists[index].specialistId)
        {
            connection.SendStats(
                {specialistSwitchEventData: {
                    browserId: Utils.GetBrowserId(),
                    specialistId: this.state.specialists[index].specialistId
                }})
        }
    }


    sendStats = (time) => {
        connection.SendStats({
            showTimeEventData: {
                browserId: Utils.GetBrowserId(),
                specialistId: this.state.selectedSpecialist?.specialistId
            }
        })
    }

    sendPreRegisterStats = (time) => {
        connection.SendStats({
            preRegisterEventData: {
                browserId: Utils.GetBrowserId(),
                selectedTime: time
            }
        })
    }

    afterLogin = () => {

        this.props.onAuth();

        this.setState({showRegister: false, authed: true, booking: true});

        this.bookConsultation(this.state.selectedTime);
    }


    render () {
        if (this.state.toSurvey)
            return <Navigate to={"/tgsurvey" + window.location.search} />

        if (this.state.tgClose)
        {
            window.Telegram.WebApp.close()
            return;
        }


        if (!this.state.analyzer)
            return <div style={{height: '600px', position: 'relative'}}><Loader /><div style={{position: 'absolute', top: '65%', width: '100%'}}><div style={{textAlign: 'center'}}>Анализируем ваш запрос</div></div></div>;

    
        if (!this.state.finder)
            return <div style={{height: '600px', position: 'relative'}}><Loader /><div style={{position: 'absolute', top: '65%', width: '100%'}}><div style={{textAlign: 'center'}}>Подбираем подходящих специалистов</div></div></div>;

        if (!this.state.loaded || !this.state.therapyMethods)
            return <div style={{height: '600px'}}><Loader /></div>;

        if (this.state.payment)
        {
            this.props.onSelected();
            return <Navigate to='/payment'/>
        }

        if (this.state.video)
            return <SpecialistVideoModal url={this.state.selectedSpecialist.videoUrl} onClose={() => this.setState({video: false})}/>;

        if (this.state.booking)
            return <div style={{height: '600px'}}><Loader /></div>;

        return <div className="showcase">


            {this.state.message ?
                <MessageModal show={true} message={this.state.message} header={"Информация"} onHide={() => this.setState({message: null})}/>
                :
                undefined
            }

            {this.state.aboutTherapy ?
            <ShowcaseTextModal text={this.state.aboutTherapy} onClose={() => this.setState({aboutTherapy: null})}/>
            :
            undefined
            }

            {this.state.showTimeSelect && !this.state.authed && !this.props.auth?
            <ShowcaseTimeSelectModal preview={true} 
                specialist={this.state.selectedSpecialist} 
                timezoneOffset={this.state.timezoneOffset} 
                onTimeSelected={(time) => this.setState({selectedTime: time, showRegister: true}, () => this.sendPreRegisterStats(time))} 
                onHide={() => this.setState({showTimeSelect: false})}
            
            
            />
            :
            undefined
            }

            {this.state.showRegister ?
                <RegisterModal onHide={() => this.setState({showRegister: false})}
                    subcategoriesId={this.state.subcategoriesId}
                    preferredGender={this.state.preferredGender}
                    timezoneOffset={this.state.timezoneOffset}
                    timePeriods={this.state.timePeriods}
                    onAuth={() => {this.afterLogin()}}
                />
                :
                undefined
            }

            {this.state.showTimeSelect && this.props.auth  ?
                <ShowcaseTimeSelectModal 
                        specialist={this.state.selectedSpecialist} 
                        timezoneOffset={this.state.timezoneOffset} 
                        onTimeSelected={(time) => this.bookConsultation(time)} 
                        onHide={() => {this.setState({showTimeSelect: false}) }}
                />
            :
                undefined
            }

            {this.state.showBookingModal && this.state.authed && false ?
                <ConsultationBookingModal 
                    specialistId={this.state.selectedSpecialist.specialistId}
                    price={this.state.selectedSpecialist.price}
                    specialistName={this.state.selectedSpecialist.name}
                    specialistPhotoUrl={this.state.selectedSpecialist.photoUrl}
                    onBooked={() => this.setState({payment: true})} 
                    onHide={() => this.props.onAuth()}
                    onSelectedTime={(selectedTime) => this.bookConsultation(selectedTime)}
                    consultationType={this.state.selectedSpecialist.consultationType}
                    timezoneOffset={this.state.timezoneOffset}
                />
            :    
                undefined
            }


            <TelegramOnboardingHeaderMobile step={2} state={this.props.state}/>

            <SliderMobile 
                onSelect={(index) => this.specialistSelected(index)} 
                specialists={this.state.specialists} 
                selectedId={this.state.specialists.findIndex(x => x == this.state.selectedSpecialist)}
            />

            <div id="general"/>
            <SpecialistBlock 
                photo={this.state.selectedSpecialist.photoUrl}
                name={this.state.selectedSpecialist.name}
                experience={this.state.selectedSpecialist.experience}
                price={this.state.selectedSpecialist.price}
            />

            <div className="problems-block">
                <div style={{marginBottom: '0.5rem'}}>Когда обращаются</div>
                <ul>
                    <li>Сильные эмоции: стресс, агрессия, гнев, вина, обида</li>
                    <li>Тревожность, постоянное внутреннее напряжение, проблемы со сном</li>
                    <li>Страхи, фобии, навязчивые мысли</li>
                    <li>Депрессия, апатия и постоянная усталость</li>
                    <li>Выгорание, сниженная продуктивность и отсутствие мотивации</li>
                    <li>Кризисные периоды: разрыв отношений, потеря или болезнь близкого человека</li>
                    <li>Трудности самоконтроля, странные повторяющиеся действия</li>
                    <li>Эмоциональная и любовная зависимость</li>
                    <li>Эмоциональная нестабильность и частая смена настроения</li>
                </ul>

                {this.state.selectedSpecialist.videoUrl ?
                <button onClick={() => this.setState({video: true})} className="problems-button">
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 7.0622V1L6.25 4.0311L11.5 7.0622L6.25 10.0933L1 13.1244V7.0622Z" stroke="white" strokeWidth="2" strokeLinejoin="round"/>
                    </svg>
                    Смотреть видеопрезентацию
                </button>
                :
                undefined
                }
            </div>

            <AboutBlock text={this.state.selectedSpecialist.about}/>

            <div id="methods"/>
            <TherapyMethodsBlock 
                onShow={(desc) => this.setState({aboutTherapy: desc})} 
                methods={this.state.selectedSpecialist.therapyMethods.map(x => { return { text: this.state.therapyMethods[x].title, description: this.state.therapyMethods[x].description } })}
            />

            <div id="education"/>
            <EducationBlock education={this.state.selectedSpecialist.education.map(x => x.description)}/>

            <div id="advantages"/>
            <AdvantagesBlock />

            <div id="faq"/>
            <FaqBlock />

            <div className="showcase-filler"></div>

            <ShowcaseMenu show={this.state.showMenu} onHide={() => this.setState({showMenu: false})}/>

            <div className="showcase-controls">
                <div className="showcase-controls-content">
                    <ShowcaseMenuButton closed={!this.state.showMenu} onClick={() => this.setState({showMenu: !this.state.showMenu})}/>
                    <button onClick={() => this.setState({showTimeSelect: true}, () => this.sendStats())} className="showcase-booking-button">Выбрать время</button>
                </div>
            </div>
        </div>;
    }
}

export default TelegramSpecialistsShowcase;