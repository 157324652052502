import React from "react";
import { Link } from "react-router-dom";


class TelegramOnboardingHeaderMobile extends React.Component {

    getStepOne = (status) => {
        if (status !== 1)
            return <Link style={{color: 'inherit'}} to={"/tgsurvey" + window.location.search}> <div className="stepper-item completed">
                        <div className="step-counter">
                        <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 2.5L2.5 4L5 1" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        </div>
                        <div className="step-name">Общие<br/>вопросы</div>
                    </div>
                    </Link>;

        return <div className="stepper-item active">
                    <div className="step-counter active"></div>
                    <div className="step-name">Общие<br/>вопросы</div>
                </div>;
    }

    getStepTwo = (status, state) => {

        if (status === 2)
            return  <div className="stepper-item active middle">
                        <div className="step-counter active"></div>
                        <div className="step-name">Выбор<br/>специалиста</div>
                    </div>;

        if (status === 1 && !state)
            return  <div className="stepper-item next middle">
                        <div className="step-counter next"></div>
                        <div className="step-name">Выбор<br/>специалиста</div>
                    </div>;

        return <Link style={{color: 'inherit'}} to={"/tgselect" + window.location.search}><div className="stepper-item completed middle">
                    <div className="step-counter">
                    <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 2.5L2.5 4L5 1" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </div>
                    <div className="step-name">Выбор<br/>специалиста</div>
                </div>
                </Link>;
    }


    getStepThree = (status, state) => {
        if (status !== 3 && state !== 3)
            return <div className="stepper-item next">
                        <div className="step-counter next"></div>
                        <div className="step-name">Запись<br/> и оплата</div>
                    </div>;
            
        if (status === 3)
            return <div className="stepper-item active">
                        <div className="step-counter active"></div>
                        <div className="step-name">Запись<br/> и оплата</div>
                    </div>;

        return <Link style={{color: 'inherit'}} to={"/payment" + window.location.search}>
                    <div className="stepper-item completed">
                        <div className="step-counter">
                            <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 2.5L2.5 4L5 1" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className="step-name">Запись<br/> и оплата</div>
                    </div>
                </Link>;
    }

    render () {

        return <div style={{marginTop: '1rem'}} className="onboarding-header">
            <div>
                <img src="images/logo.svg" alt=""/>
            </div>
            <div>
                <div className="stepper-wrapper">
                   {this.getStepOne(this.props.step, this.props.state)}
                   {this.getStepTwo(this.props.step, this.props.state)}
                   {this.getStepThree(this.props.step, this.props.state)}
                </div>
            </div>
        </div>
    }
}

export default TelegramOnboardingHeaderMobile;