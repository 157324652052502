import React from "react";


class TelegramBlockContent extends React.Component {


    // time before consultation
    // reschedule button
    // loading

    consultationTime = (time) => {
        let dt = new Date(time);
        let day = dt.getUTCDate() < 10 ? '0' + dt.getUTCDate() : dt.getUTCDate();
        let month = (dt.getUTCMonth() + 1) < 10 ? '0' + (dt.getUTCMonth() + 1) : (dt.getUTCMonth() + 1);
        let year = dt.getUTCFullYear() % 100;

        let minutes = dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
        let hours = dt.getUTCHours() < 10 ? '0' + dt.getUTCHours() : dt.getUTCHours();

        return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
    }

    deviceCheckCompleted = () => {
        localStorage.setItem('devicesChecked', true);
        let roomEnter = this.state.roomEnter;
        this.setState({deviceCheck: false, roomEnter: false}, () => {
            if (roomEnter)
                this.props.roomOpen();
        })
    }

    roomOpen = () => {
        if (!localStorage.getItem('devicesChecked'))
        {
            this.setState({roomEnter: true, deviceCheck: true});
            return;
        }
        
        this.props.roomOpen();
    }

    render() {
        return (
            <div>

                <div className="video-block-content">
                        { this.props.agenda === undefined ?
                        <span>Получение информации о предстоящей сессии...</span>
                        :

                        this.props.agenda !== null ?
                        <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center', gap: '0.5rem'}}>
                            <span  style={{textAlign: 'center'}}>Сессия запланирована на: {this.consultationTime(this.props?.agenda?.closestDate)}</span>
                            
                        </div>
                        :
                        <span style={{textAlign: 'center'}}>Нет запланированных сессий</span>
                    }
                    
                        {this.props.specialistTelegram ? 
                            <div style={{textAlign: 'center', fontSize: '12px'}}>Сессии проходят в телеграм.<br/> Аккаунт вашего психолога <a href={"https://t.me/" + this.props.specialistTelegram}>@{this.props.specialistTelegram}</a></div> 
                            :
                            undefined
                        }
                    
                </div>
            </div>
        )
    }
}

export default TelegramBlockContent;